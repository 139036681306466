.react-timerange-picker__inputGroup {
  text-align: center;
}
.react-timerange-picker__wrapper {
  border-color: #d5d5d6;
  padding: 5px 8px;
  border-radius: 4px;
  width: 285px;
}

.react-timerange-picker__clock {
  display: none;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__header {
  background: transparent !important;
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  background-color: var(--chakra-colors-blue-500) !important;
}

.printable {
  padding-left: 0.15in;
  padding-right: 0.15in;
}
